<template>
    <div class="h-100 d-flex justify-content-center align-items-center">
      <div class="text-center">
        <b-spinner variant="primary" />
        <h3 class="mt-3">Connecting your Shopify store...</h3>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ShopifyCallback',
    
    async created() {
      // Get URL parameters
      const params = new URLSearchParams(window.location.search);
      const shop = params.get('shop');
      const storeId = params.get('storeId');
      
      try {
        // Update user data to include new store
        await this.$store.dispatch('app/fetchUserData');
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Shopify store connected successfully!',
            icon: 'CheckIcon',
            variant: 'success'
          }
        });
        
        // Redirect to products page
        this.$router.push('/products');
      } catch (error) {
        console.error('Error:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to connect Shopify store',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        });
        this.$router.push('/products');
      }
    }
  }
  </script>